/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onServiceWorkerUpdateReady = () => {
  // const answer = window.confirm(
  //   'Il sito è stato aggiornato. '
  //     + 'Vuoi ricaricare la schermata per avere l\'ultima verione?',
  // );
  // if (answer === true) {
  window.location.reload();
  // }
};

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}